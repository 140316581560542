import * as http from '@/api/axios';


/**
 * 调度日志
 * @param {*} params 
 * @returns 
 */
export function taskLogs(params) {
    const url = '/webpass/task/logs';
    return http.$post(url, {
        scene: 'task',
        ...params
    });
}

/**
 * 消息日志
 * @param {*} params 
 * @returns 
 */
export function messageLogs(params) {
    const url = '/webpass/task/logs';
    return http.$post(url, {
        scene: 'message.' + params.type,
        ...params
    });
}


/**
 * 行为日志
 * @param id
 * @returns 
 */
export function actionLogs(id, reason) {
    const url = '/webpass/task/logsl';
    return http.$post(url, {
        scene: 'action',
        ...params
    });
}
