import * as app from './module/app.js';
import * as auth from './module/auth.js';
import * as webpass from './module/webpass.js';
import * as logger from './module/logger.js';
    


let api = {
    app: app,
    auth: auth,
    webpass: webpass,
    logger: logger,
};



const install = Vue => {
    if (install.installed) {
		return;
	}

    install.installed = true;
    Object.defineProperties(Vue.prototype, {
        $api: {
            get() {
                return api;
            }
        }
    });
};

export default install;
