const container = () => import('@/layout/default/container');


export default {
    path: '/webpass',
    component: container,
    children: [
        { 
            path: 'task/list', 
            name: 'webpassTaskList',
            component: () => import('@/pages/webpass/task/list')
        },
        {
            path: 'task/edit',
            name: 'webpassTaskEdit',
            component: () => import('@/pages/webpass/task/edit')
        },
        {
            path: 'task/credential/list',
            name: 'webpassTaskCredentialList',
            component: () => import('@/pages/webpass/credential/list')
        },
        {
            path: 'task/order/list',
            name: 'webpassTaskOrderList',
            component: () => import('@/pages/webpass/order/list')
        },
        {
            path: 'task/tools',
            name: 'webpassTools',
            component: () => import('@/pages/webpass/tools')
        },
    ]
}
