import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state   = {
	app      : null,
    token    : '',
	userInfo : {},
    tableId: '',
    isTried: false,
    parentComponent: {},
    component: '',
    componentConfig: {},
    promoterDrawer: false,
    flowPermission1: {},
    approverDrawer: false,
    approverConfig1: {},
    copyerDrawer: false,
    copyerConfig1: {},
    conditionDrawer: false,
    conditionsConfig1: {
        conditionNodes: [],
    },
}

/* 修改数据的方法 (同步) */
const mutations = {
    loginSuccess(store, payload) {
        store.token    = payload.token;
        store.userInfo = payload.user;
        window.localStorage.setItem('uinfo', JSON.stringify(payload));
    },
    setToken(store, token) {
        console.log(1);
        store.token = token;
    },
    setTableId(status, payload) {
        status.tableId = payload
    },
    setIsTried(status, payload) {
        status.isTried = payload
    },
    setComponent(status, payload) {
        status.component = payload
    },
    setComponentConfig(status, payload) {
        status.componentConfig = payload
    },
    setPromoter(status, payload) {
        status.promoterDrawer = payload
    },
    setFlowPermission(status, payload) {
        status.flowPermission1 = payload
    },
    setApprover(status, payload) {
        status.approverDrawer = payload
    },
    setApproverConfig(status, payload) {
        status.approverConfig1 = payload
    },
    setCopyer(status, payload) {
        status.copyerDrawer = payload
    },
    setCopyerConfig(status, payload) {
        status.copyerConfig1 = payload
    },
    setCondition(status, payload) {
        status.conditionDrawer = payload
    },
    setConditionsConfig(status, payload) {
        status.conditionsConfig1 = payload
    },
    setParentComponent(status, payload) {
        status.parentComponent = payload
    }
}

/* 提交的操作 可包含任意异步操作 */
const actions = {
	
}


export default new Vuex.Store({
	state,
	mutations,
	actions
})
