const container = () => import('@/layout/default/container');


export default {
    path: '/logger',
    component: container,
    children: [
        { 
            path: 'action/list', 
            name: 'loggerAction',
            component: () => import('@/pages/logger/action')
        },
        {
            path: 'task/list',
            name: 'loggerTask',
            component: () => import('@/pages/logger/task')
        },
        {
            path: 'message/list',
            name: 'loggerMessage',
            component: () => import('@/pages/logger/message')
        },
    ]
}
