const container = () => import('@/layout/default/container');


export default {
    path: '/other',
    component: container,
    children: [
        { 
            path: 'other/setting', 
            name: 'userSetting',
            component: () => import('@/pages/setting/index')
        },
    ]
}
