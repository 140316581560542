import * as http from '@/api/axios';


/**
 * 部门列表
 * @returns 
 */
export function deptList(params) {
    const url = '/admin/auth/dept/list';
    return http.$post(url, params);
}



/**
 * 用户列表
 * @returns 
 */
export function userList(params) {
    const url = '/admin/auth/user/list';
    return http.$post(url, params);
}



/**
 * 角色列表
 * @returns 
 */
export function roleList(params) {
    const url = '/admin/auth/role/list';
    return http.$post(url, params);
}


/**
 * 角色列表
 * @returns 
 */
export function createRole(data) {
    const url = '/admin/auth/role/create';
    return http.$post(url, data);
}


/**
 * 删除角色
 * @returns 
 */
export function deleteRole(data) {
    const url = '/admin/auth/role/delete';
    return http.$post(url, data);
}


/**
 * 角色下的用户列表
 * @returns 
 */
export function roleUserList(id) {
    const url = '/admin/auth/role/users';
    return http.$post(url, { id: id });
}


/**
 * 角色下新增用户
 * @returns 
 */
export function addRoleUser(data) {
    const url = '/admin/auth/role/user/create';
    return http.$post(url, data);
}

/**
 * 角色下删除用户
 * @returns 
 */
export function deleteRoleUser(data) {
    const url = '/admin/auth/role/user/delete';
    return http.$post(url, data);
}


/**
 * 菜单列表
 * @returns 
 */
export function menuList(data) {
    const url = '/admin/auth/menu/list';
    return http.$post(url, data);
}