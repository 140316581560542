const container = () => import('@/layout/default/container');


export default {
    path: '/workflow',
    component: container,
    children: [
        {
            path: 'approve/list',
            name: 'workflowApproveList',
            component: () => import('@/pages/workflow/approve/list')
        },
        {
            path: 'approve/field',
            name: 'webpassApproveUpdateField',
            component: () => import('@/pages/workflow/approve/field')
        },
        {
            path: 'approve_instance/list',
            name: 'workflowApproveInstanceList',
            component: () => import('@/pages/workflow/approve_instance/list')
        },
    ]
}
