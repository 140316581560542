import * as http from '@/api/axios';


/**
 * 获取调度任务列表
 * @param {*} params 
 * @returns 
 */
export function taskList(params) {
    const url = '/webpass/task/list';
    return http.$post(url, params);
}

/**
 * 获取调度任务详情
 * @param {*} params 
 * @returns 
 */
export function taskInfo(id) {
    const url = '/webpass/task/info';
    return http.$post(url, {id:id});
}


/**
 * 更新调度任务
 * @param {*} params 
 * @returns 
 */
export function taskUpdate(id, data) {
    const url = '/webpass/task/update';
    return http.$post(url, { id: id, ...data });
}





/**
 * 测试调度任务
 * @param {*} params 
 * @returns 
 */
export function taskTest(data) {
    const url = '/webpass/task/test';
    return http.$post(url, data);
}

/**
 * 开启或关闭调度任务
 * @param {*} params 
 * @returns 
 */
export function taskOpenOrClose(id, enable) {
    const url = '/webpass/task/enable';
    return http.$post(url, {
        id: id,
        enable: enable
    });
}



/**
 * 添加忽略订单
 * @param {*} params 
 * @returns 
 */
export function addIgnoreOrder(data) {
    const url = '/webpass/task/order/add_ignore';
    return http.$post(url, data);
}


/**
 * 获取调度凭证列表
 * @param {*} params 
 * @returns 
 */
export function taskCredentialList(params) {
    const url = '/webpass/task/credential/list';
    return http.$post(url, params);
}


/**
 * 创建凭证
 * @param {*} data 
 * @returns 
 */
export function taskCredentialCreate(data) {
    const url = '/webpass/task/credential/create';
    return http.$post(url, data);
}



/**
 * 获取调度任务-单据列表
 * @param {*} params 
 * @returns 
 */
export function taskOrderList(params) {
    const url = '/webpass/task/order/list';
    return http.$post(url, params);
}


/**
 * 取消单据
 * @param id
 * @returns 
 */
export function taskOrderCancel(data) {
    const url = '/webpass/task/order/cancel';
    return http.$post(url, data);
}



/**
 * 消费消息
 * @param id
 * @returns 
 */
export function taskMessageConsume(type, id) {
    const url = '/webpass/task/message/consume';
    return http.$post(url, {
        id: id,
        type: type
    });
}


/**
 * 忽略消息
 * @param id
 * @returns 
 */
export function taskMessageIgnore(type, id) {
    const url = '/webpass/task/message/ignore';
    return http.$post(url, {
        id: id,
        type: type
    });
}

/**
 * 查看表
 * @param {*} params 
 * @returns 
 */
export function getTables(id) {
    const url = '/webpass/task/credential/tables';
    return http.$post(url, {
        id: id,
    });
}



/**
 * 审批定义列表
 * @param {*} data 
 * @returns 
 */
export function approveList(data) {
    const url = '/webpass/approval/list';
    return http.$post(url, data);
}


/**
 * 审批定义详情
 * @param {*} id 
 * @returns 
 */
export function approveInfo(id) {
    const url = '/webpass/approval/info';
    return http.$post(url, {approve_id:id});
}



/**
 * 审批行为
 * @returns 
 */
export function approveActionList() {
    const url = '/webpass/approval/action';
    return http.$post(url);
}



/**
 * 审批map
 * @returns 
 */
export function approveFormInfo(data) {
    const url = '/webpass/approval/map';
    return http.$post(url, data);
}




/**
 * 修改审批映射
 * @returns 
 */
export function approveUpdate(data) {
    const url = '/webpass/approval/update';
    return http.$post(url, data);
}


/**
 * 审批实例列表
 * @param {*} params 
 * @returns 
 */
export function approveInstanceList(data) {
    const url = '/webpass/approval_instance/list';
    return http.$post(url, data);
}



/**
 * 同步物料
 * @param {*} params 
 * @returns 
 */
export function syncMaterial(data) {
    const url = '/webpass/task/material/push';
    return http.$post(url, data);
}


/**
 * @param {*} params 
 * @returns 
 */
export function cosmeticFormulaList(data) {
    const url = '/webpass/cosmetic/formula/list';
    return http.$post(url, data);
}



/**
 * @param {*} params 
 * @returns 
 */
export function setCosmeticFormulaStatus(id, status) {
    const url = '/webpass/cosmetic/formula/status';
    return http.$post(url, {id:id, status: status});
}



/**
 * @param {*} params 
 * @returns 
 */
export function getCosmeticFormulaOcrResponse(json) {
    const url = '/webpass/cosmetic/formula/ocr';
    return http.$post(url, { json: json });
}


/**
 * @param {*} params 
 * @returns 
 */
export function setCosmeticFormulaOcrResponse(data) {
    const url = '/webpass/cosmetic/formula/ocr_set';
    return http.$post(url, data);
}


/**
 * @param {*} params 
 * @returns 
 */
export function searchCosmeticFormulaMaterials(data) {
    const url = '/webpass/cosmetic/formula/material_search';
    return http.$post(url, data);
}


/**
 * @param {*} params 
 * @returns 
 */
export function getSetting(scene = 'default') {
    const url = '/webpass/setting/get';
    return http.$post(url, { scene: scene });
}


/**
 * @param {*} params 
 * @returns 
 */
export function updateSetting(form, scene = 'default') {
    const url = '/webpass/setting/update';
    return http.$post(url, { form, scene: scene });
}



export function getAnchorProductSalesPanel(data) {
    const url = '/anchor/product_sales_panel';
    return http.$post(url, data);
}

export function getAnchorProductSalesPanelItem(data) {
    const url = '/anchor/product_sales_panel_item';
    return http.$post(url, data);
}


export function getAnchorPlatformSalesPanel(data) {
    const url = '/anchor/platform_sales_panel';
    return http.$post(url, data);
}



export function getProductSalePanelPie(data) {
    const url = '/anchor/product_sales_panel_pie';
    return http.$post(url, data);
}

export function getPlatformSalePanelPie(data) {
    const url = '/anchor/platform_sales_panel_pie';
    return http.$post(url, data);
}

export function downloadAnchorSales(data) {
    const url = '/anchor/sales_download';
    return http.$post(url, data);
}