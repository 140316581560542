import * as http from '@/api/axios';


/**
 * 飞书登录
 * @param {*} params 
 * @returns 
 */
export function login(id) {
    const url = '/login';
    return http.$post(url, {
        code: id,
        appid: 'cli_a4769c49113a1013',
        platform: 'webpass'
    });
}



/**
 * 调度日志
 * @param {*} params 
 * @returns 
 */
export function getEntry(id) {
    const url = '/kcloud/entry';
    return http.$post(url, {
        formid: id,
    });
}



/**
 * 熔断
 * @param {*} params 
 * @returns 
 */
export function fusing(scene, value) {
    return http.$post('/fusing', {
        _method: scene,
        _value: value,
    });
}




/**
 * 调度日志
 * @param {*} params 
 * @returns 
 */
export function jsapi(data) {
    return http.$post('/admin/auth/user/jsapi', data);
}