<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "app",
    data() {
        return {
			app_id: 'cli_a4769c49113a1013',
        }
    },
    methods: {
        async init() {
			let that = this;
			if (!window.h5sdk) return;
			// 飞书
			window.h5sdk.ready(async () => {
                const jsapi = await that.$api.app.jsapi({
                    url: location.href.split("#")[0],
                    app_id: that.app_id,
                });
                console.log('jsapi config is ', jsapi);
				window.h5sdk.config({
                    appId: jsapi.data.appid,
                    timestamp: jsapi.data.timestamp,
                    nonceStr: jsapi.data.noncestr,
                    signature: jsapi.data.signature,
                    jsApiList: [
                        "chooseContact"
                    ],
                    //鉴权成功回调
                    onSuccess: (res) => {
                        console.log(`feishu set config success`);
                    },
                    //鉴权失败回调
                    onFail: (err) => {
                        console.log(`config failed: `, err);
                    },
                });
			})
		},
    },
    mounted() {
        this.init();
        //const vConsole = new VConsole();
    },
    created() {
        let that = this;
        const info = window.localStorage.getItem('uinfo');
        if (info) {
            that.$store.commit('loginSuccess', JSON.parse(info));
        }
    },
};
</script>

<style lang="less">
@import "./assets/css/iconfont/iconfont.css";
@import "./assets/css/animate.css";
@import "./assets/css/app.css";
/*//滚动条*/
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
  background: #F0F0F0;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.0);
  border-radius: 0;
  background: transparent;
}
</style>
