const container = () => import('@/layout/default/container');


export default {
    path: '/auth',
    component: container,
    children: [
        { 
            path: 'user/list', 
            name: 'userList',
            component: () => import('@/pages/auth/user/list')
        },
        {
            path: 'role/list',
            name: 'roleList',
            component: () => import('@/pages/auth/role/list')
        },
        {
            path: 'menu/list',
            name: 'menuList',
            component: () => import('@/pages/auth/menu/list')
        },
    ]
}
